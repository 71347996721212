<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="1200px" @closed="form = null">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-position="right" label-suffix=":">
          <div class="h">
            <el-form-item label="出库库房" v-show="lockWarehouse">
              <div style="width: 180px;">{{form.warehouseName || "请输入调拨单号"}}</div>
            </el-form-item>
            <el-form-item label="出库库房" prop="warehouseId" v-show="!lockWarehouse">
              <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName" auto-select-single-option displayField="name" url="api/warehouse" filterable @change="handleWarehouseChange" placeholder="请选择库房" style="width: 180px;" />
            </el-form-item>
            <el-form-item label="出库时间" prop="formTime" label-width="120px">
              <el-date-picker v-model="form.formTime" :clearable="false" value-format="timestamp" style="width: 180px;" readonly />
            </el-form-item>
            <el-form-item prop="manager" label="出库人" label-width="120px">
              <el-input v-model="form.manager" :maxlength="25" style="width: 180px;" />
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="customer" label="姓名">
              <el-input v-model="form.customer" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="telephone" label="电话" label-width="120px">
              <el-input v-model="form.telephone" style="width: 180px;" />
            </el-form-item>
            <el-form-item prop="address" label="详细地址" label-width="120px">
              <el-input v-model="form.address" style="width: 250px;" />
            </el-form-item>
          </div>
          <!-- <div class="h">
            <el-form-item label="出库类型" prop="formType">
              <el-select v-model="form.formType" filterable placeholder="请选择出库类型" @change="handleFormTypeChange" style="width: 180px;">
                <el-option v-for="(v, k) in storageTypes" :key="k" :value="parseInt(k)" :label="v" />
              </el-select>
            </el-form-item>
            <template v-if="!mode">
              <el-form-item prop="dependFormCode" label="发货通知单号" label-width="120px" v-if="form.formType === 2">
                <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入发货通知单号查询并填入明细" @change="loadConsignmentOrder" style="width: 480px;">
                  <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
                </el-input>
              </el-form-item>
              <el-form-item prop="dependFormCode" label="退货单号" label-width="120px" v-else-if="form.formType === 4">
                <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入退货单号查询并填入明细" @change="loadReturnOrder" style="width: 480px;">
                  <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
                </el-input>
              </el-form-item>
              <el-form-item prop="dependFormCode" label="调拨单号" label-width="120px" v-else-if="form.formType === 6">
                <el-input v-model.trim="form.dependFormCode" :readonly="referenceLoading" placeholder="输入调拨单号查询并填入明细" @change="loadAllocationOrder" style="width: 480px;">
                  <el-button slot="append" icon="el-icon-search" :loading="referenceLoading" />
                </el-input>
              </el-form-item>
            </template>
          </div>-->
          <el-form-item label="出库明细" style="margin-bottom: 12px !important; z-index: 2;" required v-if="form.formType === 0">
            <sku-selector request-url="api/warehouseGoods" :request-params="{warehouseIds: [form.warehouseId],minCount:0.01}" :exclude-keys="excludeGoods" @submit="handleGoodsAdd" />
            <!-- <datagrid-picker reference="选择商品" reference-type="primary" url="api/goods/sku" :exclude-keys="excludeGoods" :query-define="goodsQueryDefine" :popper-width="1000" :show-index="false" multiple submit @submit="handleGoodsAdd">
              <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                <el-input :maxlength="10" v-model="goodsQueryDefine.keyword" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入商品名称进行搜索" style="width: 240px;">
                  <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
                </el-input>
              </div>
              <el-table-column prop="name" label="商品名称" min-width="240" />
              <el-table-column label="规格" min-width="120">
                <template slot-scope="scope">{{$goodsSpecConvert(scope.row.specs)}}</template>
              </el-table-column>
              <el-table-column prop="brandName" label="品牌" width="120" />
              <el-table-column prop="seriesName" label="系列" width="120" show-overflow-tooltip />
            </datagrid-picker>-->
          </el-form-item>
          <el-form-item prop="items" :label="form.formType === 0 ? '' : '出库明细'">
            <el-table :data="form.items" class="table-outdent" show-summary :summary-method="getSummaries">
              <el-table-column prop="code" label="商品编码" width="130" />
              <el-table-column prop="erpCode" label="ERP编码" width="130" />
              <el-table-column prop="goodsName" label="名称" min-width="200" />
              <el-table-column prop="specs" label="规格" min-width="130" :formatter="$goodsSpecConvert" />
              <el-table-column prop="brandName" label="品牌" width="100" />
              <!-- <el-table-column prop="seriesName" label="系列" width="120" /> -->
              <el-table-column prop="_currentStock" label="当前库存" width="100" align="right" />
              <el-table-column prop="realCount" label="出库数量" width="100" align="right">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.realCount" :min="0.01" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" @change="$checkNaN(scope.row,'realCount',1)" style="width: 100%;" v-if="form.formType === 0" />
                  <span v-else>{{scope.row.realCount}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="info" label="备注" width="140">
                <template slot-scope="scope">
                  <el-input type="textarea" v-model="scope.row.info" rows="1" />
                </template>
              </el-table-column>
              <el-table-column width="40" v-if="form.formType === 0">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-delete" size="mini" @click="handleGoodsRemove(scope.row)" />
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item prop="info" label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="100" :rows="3" resize="none" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button :loading="saving" type="primary" @click="doSave" :disabled="submiting">保存</el-button>
          <el-button :loading="submiting" type="danger" @click="doSubmit" :disabled="saving" v-if="form.id">保存并出库</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
          <div class="h sb">
            <div>
              <form-info-item label="出库单号">{{form.formCode}}</form-info-item>
              <form-info-item label="出库库房">{{form.warehouseName}}</form-info-item>
              <form-info-item label="出库时间">{{new Date(form.formTime).format('yyyy/MM/dd')}}</form-info-item>
              <form-info-item label="姓名">{{form.customer}}</form-info-item>
            </div>
            <div>
              <form-info-item label="出库方式">{{storageViewTypes[form.formType]}}</form-info-item>
              <form-info-item label="发货单号">{{form.dependFormCode}}</form-info-item>
              <form-info-item label="出库人" v-if="form.manager">{{form.manager}}</form-info-item>
              <form-info-item label="电话">{{form.telephone}}</form-info-item>
            </div>
          </div>
          <form-info-item label="详细地址">{{form.address}}</form-info-item>
          <el-table border :data="form.items" highlight-current-row empty-text="该订单没有出库的商品" show-summary :summary-method="getSummaries" style="margin: 8px 0;">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column prop="goodsName" label="名称" min-width="240" />
            <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
            <el-table-column prop="brandName" label="品牌" width="120" />
            <!-- <el-table-column prop="seriesName" label="系列" width="120" /> -->
            <el-table-column prop="_currentStock" label="当前库存" width="100" align="right" />
            <el-table-column prop="realCount" label="出库数量" width="100" align="right" />
            <el-table-column label="备注" width="100" show-overflow-tooltip>
              <template slot-scope="scope">{{scope.row.info}}</template>
            </el-table-column>
          </el-table>
          <form-info-item label="备注">{{form.info}}</form-info-item>
          <el-divider />
          <div class="h c">
            <form-info-item label="当前状态" class="flex">
              <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
            </form-info-item>
            <el-button type="text" @click="dialog = false">取消</el-button>
          </div>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>

<script>
import {
  get,
  add,
  edit,
  finish,
  getByConsignmentOrder,
} from "@/api/storageManage";
import { get as getPurchaseOrder } from "@/api/purchaseOrder";
import { getGoodsById } from "@/api/warehouse";
import { mapGetters } from "vuex";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";

export default {
  components: { skuSelector },
  data() {
    return {
      loading: false,
      referenceLoading: false, //参考单据加载（如加载发货通知单、退货单等）
      saving: false,
      submiting: false,
      searching: false,
      dialog: false,
      mode: null,
      goodsQueryDefine: {
        keyword: "",
      },
      storageTypes: {
        2: "销售出库",
        4: "退货出库",
        // 6: "调拨出库",
        0: "其他",
      },
      storageViewTypes: {
        2: "销售出库",
        4: "退货出库",
        6: "调拨出库",
        8: "门店销售出库",
        0: "其他",
      },
      status: [
        { label: "编辑中", type: "info" },
        { label: "已出库", type: "success" },
      ],
      form: null,
      rules: {
        customer: [{ required: true, message: "请填写姓名" }],
        telephone: [{ required: true, message: "请填写电话" }],
        address: [{ required: true, message: "请填写详细地址" }],
        formTime: [
          { required: true, message: "请选择出库日期", trigger: "change" },
        ],
        warehouseId: [
          { required: true, message: "请选择出库库房", trigger: "change" },
        ],
        items: [
          { required: true, message: "请填写单据或者添加出库明细" },
          { type: "array", min: 1, message: "请填写单据或者添加出库明细" },
        ],
        dependFormCode: [{ required: true, message: "请输入相应的单号" }],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    excludeGoods() {
      return (this.form.items || []).map((o) => {
        return o.goodsId;
      });
    },
    title() {
      let str = "加载中…";
      if (this.form) {
        return this.editable
          ? this.form.id
            ? "编辑出库单"
            : "新建出库单"
          : "查看出库单";
      }
      return str;
    },
    editable() {
      return this.form && this.form.status === 0;
    },
    lockWarehouse() {
      let lock = true;
      if (this.form) {
        lock = this.form.formType === 2 || this.form.formType === 6;
      }
      return lock;
    },
  },
  methods: {
    handleFormTypeChange() {
      this.form.dependFormId = "";
      this.form.dependFormCode = "";
      this.form.items = [];
      if (this.form.formType === 5) {
        this.form.warehouseId = null;
        this.form.warehouseName = "";
      }
    },
    loadConsignmentOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        this.referenceLoading = true;
        request({
          url: "api/invoice/unsend/" + this.form.dependFormCode,
          method: "get",
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.items = (res.items || []).map((o) => {
                let _no = {
                  brandName: o.brandName,
                  realCount: o.goodsCount,
                  goodsCount: o.goodsCount,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.seriesName,
                  specs: o.specName,
                  code: o.code,
                  erpCode: o.erpCode,
                  dependFormCode: o.dependFormCode,
                  _currentStock: 0,
                };
                this.loadStockInfo(_no);
                return _no;
              });
            } else {
              this.$alert("没有找到对应的发货通知单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    loadReturnOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        this.referenceLoading = true;
        request({
          url: "api/orderBackForm/UnOut/" + this.form.dependFormCode,
          method: "get",
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.items = (res.items || []).map((o) => {
                let _no = {
                  brandName: o.goodsBrandName,
                  realCount: o.count,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeriesName,
                  specs: o.goodsSpec,
                  _currentStock: 0,
                };
                this.loadStockInfo(_no);
                return _no;
              });
            } else {
              this.$alert("没有找到对应的退货单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    loadAllocationOrder() {
      this.form.items = [];
      if (this.form && this.form.dependFormCode) {
        this.referenceLoading = true;
        request({
          url: "api/allocationForm/findByCode",
          method: "post",
          data: {
            formCode: this.form.dependFormCode,
            status: 1,
          },
        })
          .then((res) => {
            if (res) {
              this.form.dependFormId = res.id;
              this.form.items = (res.items || []).map((o) => {
                let _no = {
                  brandName: o.goodsBrandName,
                  realCount: o.count,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeriesName,
                  code: o.code,
                  erpCode: o.erpCode,
                  specs: o.goodsSpec,
                  _currentStock: 0,
                };
                this.loadStockInfo(_no);
                return _no;
              });
            } else {
              this.$alert("没有找到对应的调拨单", "系统提示", {
                type: "warning",
              });
            }
          })
          .finally((err) => {
            this.referenceLoading = false;
          });
      }
    },
    findByConsignmentOrder(order) {
      this.loading = true;
      getByConsignmentOrder(order.id)
        .then((res) => {
          if (res) {
            if (res.items && res.items.length) {
              res.items.forEach((o) => {
                o._currentStock = 0;
              });
            }
            this.form = res;
            this.handleWarehouseChange();
          } else {
            this.resetForm(null, 2, order);
          }
        })
        .finally((_) => {
          this.loading = false;
        });
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then((res) => {
              if (res) this.resetForm(res);
              if (!this.mode) this.$parent.init();
              if (typeof callback === "function") callback();
              else
                this.$notify({
                  title: `保存出库单成功`,
                  type: "success",
                  duration: 2500,
                });
            })
            .finally((_) => {
              this.saving = false;
            });
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm("您确定要进行出库吗？", "出库确认", {
          type: "warning",
          dangerouslyUseHTMLString: true,
        }).then((res) => {
          this.doSave((_) => {
            this.submiting = true;
            finish(this.form.id)
              .then((res) => {
                this.$notify({
                  title: "出库确认成功",
                  type: "success",
                  duration: 2500,
                });
                switch (this.mode) {
                  case "purchase":
                    this.$alert(
                      `该采购单已经出库成功，出库单号为：${this.form.formCode}。<br />在“库房管理”中可查看出库单或库存情况。`,
                      "出库提示",
                      {
                        dangerouslyUseHTMLString: true,
                      }
                    );
                    break;
                  default:
                    this.$parent.init();
                    break;
                }
                this.dialog = false;
              })
              .finally((_) => {
                this.submiting = false;
              });
          });
        });
      }
    },
    loadStockInfo(g) {
      if (g && g.goodsId && this.form.warehouseId) {
        getGoodsById(g.goodsId, [this.form.warehouseId])
          .then((res) => {
            if (res && res.length) {
              g._currentStock = res[0].count;
            } else {
              g._currentStock = 0;
            }
          })
          .catch((err) => {
            g._currentStock = 0;
          });
      } else {
        g._currentStock = 0;
      }
    },
    handleWarehouseChange() {
      if (this.form && this.form.items && this.form.items.length) {
        this.form.items.forEach((o) => {
          this.loadStockInfo(o);
        });
      }
    },
    find(source, type) {
      switch (type) {
        case 2:
          this.findByConsignmentOrder(source);
          break;
      }
      this.dialog = true;
    },
    resetForm(form, type, referenceOrder) {
      this.mode = null;
      if (form && form.id) {
        this.loading = true;
        get(form.id)
          .then((res) => {
            if (res.items && res.items.length) {
              res.items.forEach((o) => {
                o._currentStock = 0;
              });
            }
            this.form = res;
            this.handleWarehouseChange();
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = Object.assign(
          {
            status: 0,
            formTime: new Date().getTime(),
            manager: this.user.userRealName,
            warehouseId: null,
            warehouseName: "",
            formType: type || 0,
            inoutType: 1,
            info: null,
            dependFormCode: referenceOrder ? referenceOrder.formCode : "",
            items: [],
          },
          form || {}
        );
        if (referenceOrder) {
          switch (type) {
            case 2:
              this.form.warehouseId = referenceOrder.warehouseId;
              this.loadConsignmentOrder();
              break;
            case 4:
              this.loadReturnOrder();
              break;
            case 6:
              this.form.warehouseId = referenceOrder.outWarehouseId;
              this.loadAllocationOrder();
              break;
          }
        }
      }
      this.dialog = true;
    },
    generateByPurchaseOrder(orderId) {
      if (orderId) {
        this.mode = "purchase";
        this.loading = true;
        getPurchaseOrder(orderId)
          .then((res) => {
            this.form = {
              status: 0,
              formTime: new Date().getTime(),
              manager: "",
              warehouseId: null,
              formType: 0,
              inoutType: 1,
              info: null,
              dependFormCode: "",
              items: (res.items || []).map((o) => {
                return {
                  brandName: o.goodsBrand,
                  goodsCount: o.count,
                  goodsId: o.goodsId,
                  goodsName: o.goodsName,
                  seriesName: o.goodsSeries,
                  specs: o.goodsSpec,
                };
              }),
            };
          })
          .finally((_) => {
            this.loading = false;
          });
        this.dialog = true;
      }
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        let ds = this.form.items || [];
        list.forEach((o) => {
          let _no = {
            brandName: o.brandName,
            realCount: 1,
            code: o.code,
            erpCode: o.erpCode,
            goodsId: o.id,
            _currentStock: 0,
            goodsName: o.name,
            seriesName: o.seriesName,
            specs: o.specs,
          };
          this.loadStockInfo(_no);
          ds.push(_no);
        });
        this.form.items = ds;
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      if (this.form && this.form.items && this.form.items.length) {
        let inx = this.form.items.findIndex((o) => {
          return o.goodsId === row.goodsId;
        });
        if (inx >= 0) this.form.items.splice(inx, 1);
      }
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 1 || index === 7) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += "";
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
  },
};
</script>
